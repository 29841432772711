import {
  AlcoholFreeZoneTypes,
  CoverTypes,
  SeatSelectTypes,
  SeatsIOSeat,
  SeatsIOCategory,
  SeatsIOZone,
  SortTypes,
} from "~components/reservation/constants";

import { EventQuery, MembershipType, TicketType } from "~graphql/sdk";
import { Release } from "../types";
export type SeatType = {
  id: string;
  ticketType: { label: string; value: string } & Partial<MembershipType> &
    Partial<TicketType>;
};

export type OptionKey = keyof StoreState["options"];
export type Options = Partial<Record<OptionKey, any>>;

export type BusyState = "idle" | "finding-best-seats" | "creating-order";

type Chart = {
  holdToken: string;
  selectedObjects: any[];
  listCategories: () => Promise<SeatsIOCategory[]>;
  startNewSession: () => Promise<void>;
  clearSelection: () => Promise<void>;
  resetView: () => Promise<void>;
  zoomToFilteredCategories: () => Promise<void>;
  zoomToSelectedObjects: () => Promise<void>;
  findObject: (label: string) => Promise<SeatsIOSeat>;
  selectObjects: (labels: string[]) => Promise<void>;
  changeConfig: (config: { [key: string]: any }) => Promise<void>;
} & {
  [key: string]: any;
};

export interface StoreState {
  chart: Chart;
  orgId: string | undefined;
  isAdmin: boolean;
  eventTicketTypes: EventQuery["event"]["ticketTypes"];
  multiBuyPromotions: EventQuery["event"]["multiBuyPromotions"];
  seatsEventKey: string | undefined;
  busyState: BusyState;
  sidebarIndex: number;
  categoryKey: number[] | undefined;
  chartReady: boolean;
  seats: SeatsIOSeat[];
  selectFailed: boolean;
  ticketDetails: any;
  availableTicketTypes: any[];
  zones: SeatsIOZone[] | undefined;
  selectableZones: SeatsIOZone[] | undefined;
  filteredZones: SeatsIOZone[] | undefined;
  release: Release;
  releasedZones: any[];
  addons: { [id: string]: number } | undefined;
  selectionType: "user" | "auto" | "none";
  currentZone: any | undefined;
  currentSection: any | undefined;
  validSeatSelection: boolean;
  selectBestOnChange: boolean;
  unmetPurchaseRestrictionsMessage: string | undefined;
  options: {
    filterActive: boolean;
    amount: number;
    seatSelectType: SeatSelectTypes;
    coverType: CoverTypes;
    zoneType: AlcoholFreeZoneTypes;
    selectedSort: SortTypes;
  };
}

export const initialState: StoreState = {
  busyState: "idle",
  chartReady: false,
  sidebarIndex: 1,
  seats: [],
  categoryKey: undefined,
  orgId: undefined,
  isAdmin: false,
  eventTicketTypes: [],
  availableTicketTypes: [],
  multiBuyPromotions: [],
  seatsEventKey: undefined,
  ticketDetails: undefined,
  chart: undefined,
  zones: undefined,
  filteredZones: undefined,
  selectableZones: undefined,
  release: undefined,
  releasedZones: undefined,
  addons: undefined,
  selectionType: "none",
  selectFailed: false,
  currentZone: undefined,
  currentSection: undefined,
  validSeatSelection: false,
  selectBestOnChange: true,
  unmetPurchaseRestrictionsMessage: undefined,
  options: {
    filterActive: false,
    amount: 2,
    seatSelectType: SeatSelectTypes.QUICK_SELECT,
    coverType: CoverTypes.ALL,
    zoneType: AlcoholFreeZoneTypes.NO_PREFERENCE,
    selectedSort: SortTypes.BEST_AVAILABLE,
  },
};
