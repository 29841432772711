import { useEffect, useState } from "react";
import { useQuery } from "~hooks/useQuery";
import {
  EventAccessStatus,
  GetEventAndReleaseForCustomerDocument,
} from "~graphql/typed-document-nodes";
import { getError, handlePromise, showToast } from "~lib";
import { useRouter } from "next/router";
import { ToastType } from "react-toastify";
import * as actions from "~features/nonseated-reservation/store/actions";
import { useOrganization } from "./useOrganization";
import { getEvents } from "~features/events/hooks/useEventsService";
import { useSDK } from "./useSDK";
import { sdk } from "../lib/graphQL/index";

type QueryParams = {
  eventId?: string;
  release?: string;
  slug?: string;
  code?: string;
};

export const useEventAccessControl = (userEventId?: string) => {
  const router = useRouter();
  const { organization, hasFeature } = useOrganization();
  const sdk = useSDK();
  const [ready, setReady] = useState(false);
  const [checkingCode, setCheckingCode] = useState(router.query.code);

  const query = router.query as QueryParams;

  const eventId = query.eventId ?? userEventId;
  const releaseId = query.release;

  const { isLoading, data, error, isValidating } = useQuery(
    eventId ? GetEventAndReleaseForCustomerDocument : null,
    {
      input: {
        eventId,
        releaseId,
        releaseSlug: query.slug, // && !query.code ? query.slug : undefined,
      },
    },
    {
      onSuccess: (data) => {
        const { event, release } = data.getEventAndReleaseForCustomer;
        actions.updateTrackingContext({
          id: event.id,
          name: event.title,
          releaseId: release?.id,
          releaseName: release?.name,
        });
      },
    }
  );

  const { event, release, isAdmin, accessType } =
    data?.getEventAndReleaseForCustomer ?? {};

  async function findNextAvailableEvent() {
    const events = await getEvents(undefined, organization.id);

    // TODO: unhappy path
    const nextUpEvent = events[0];

    return nextUpEvent;
  }

  useEffect(() => {
    if (isLoading) return;

    async function checkCodeAndMaybeRedirect(eventId: string) {
      return sdk
        .validateReleasePasswordOrRelease({
          eventId,
          password: router.query?.code as string,
        })
        .then(async (res) => {
          console.log({ slug: router.query.slug, res });
          if (router.query.slug !== res.validateReleasePasswordOrRelease.slug) {
            return router.push(
              {
                pathname: router.pathname,
                query: {
                  ...router.query,
                  slug: res.validateReleasePasswordOrRelease.slug,
                  eventId,
                },
              },
              undefined,
              // Shallow because we don't need server functions to run again
              { shallow: true }
            );
          }
        })
        .then(() => {
          setReady(true);
        })
        .catch((err) => {
          console.error(err);
          // Remove the additional query params if we annot validate the code
          void router.replace({
            pathname: router.pathname,
            query: { eventId },
          });
          // showToast("Acccess code is invalid.", ToastType.ERROR);
        });
    }

    if (getError(error, "graphQL") === EventAccessStatus.Denied) {
      void router.push("/");
      void showToast(
        "This event is not public. Admin only access.",
        ToastType.ERROR
      );
    } else if (getError(error, "graphQL") === EventAccessStatus.EventEnded) {
      if (hasFeature("eventCalendarHomepage")) {
        void findNextAvailableEvent().then((event) => {
          if (router.query.code) {
            void checkCodeAndMaybeRedirect(event.id);
          }
        });
      } else {
        void router.push("/");
        void showToast("This event has finished.", ToastType.ERROR);
      }
    } else if (router.query.code) {
      void checkCodeAndMaybeRedirect(eventId);
    } else {
      setReady(true);
    }
  }, [eventId, error, isLoading, router.query.code]);

  return {
    isLoading: isLoading || !ready,
    release,
    isAdmin,
    event,
    error,
    isValidating,
    accessType,
    ready,
  };
};
