import { useEffect, useState } from "react";

import flatten from "lodash/flatten";

import { SeatsIOSeat, SeatsIOZone } from "~components/reservation/constants";
import { Zone, ZonePricingLevel } from "~graphql/sdk";
import { getCategoryDataFromLabel } from "~lib/helpers";

interface PriceRange {
  min: number;
  max: number;
}

interface PricedZone extends Zone {
  priceRange: PriceRange;
}

type PricingHookProps = {
  filteredZones: SeatsIOZone[];
  releasedZones: any[];
  type: "event" | "membership" | "release";
  isAdmin?: boolean;
  activePromotions?: any[];
};

export type PricingHookResponse = {
  pricedZones: PricedZone[];
  getPrice: (seat: SeatsIOSeat) => [number, number];
};

type PricingHook = ({
  filteredZones,
  releasedZones,
  type,
  isAdmin,
  activePromotions,
}: PricingHookProps) => PricingHookResponse;

const getPriceRange = (ticketTypes, isAdmin) => {
  ticketTypes = ticketTypes?.filter((tt) => tt.price != null);

  return {
    min:
      ticketTypes?.reduce(
        (min, i) =>
          isAdmin
            ? i.price < min
              ? i.price
              : min
            : i.price < min && i.price > 0
            ? i.price
            : min,
        ticketTypes[0]?.price
      ) ?? 0,
    max:
      ticketTypes?.reduce(
        (max, i) => (i.price > max ? i.price : max),
        ticketTypes[0]?.price
      ) ?? 0,
  };
};

const addPricingToSection = (sections, releasedSection, type) => {
  const zoneSection = sections?.find(
    ({ name }) => releasedSection.name === name
  );

  return zoneSection;
};

// filteredZones is just an array of zones, releaseZones is an array of releaseZones with nested linked zone
const addPricingToZone = (filteredZones, releasedZones, type, isAdmin) => {
  const typeKey = type === "membership" ? "membershipTypes" : "ticketTypes";
  return (
    filteredZones?.map((filteredZone) => {
      const releasedZone = releasedZones.find(
        (releaseZone) => releaseZone.zone.name === filteredZone.name
      );

      if (releasedZone.pricingLevel === ZonePricingLevel.Section) {
        const sections = filteredZone.sections
          ?.map((section) => {
            const sectionTicketTypes = flatten(
              releasedZone[typeKey]
                ?.map((ticketType) => ({
                  price: ticketType?.sections?.find(
                    ({ name }) => section.name === name
                  )?.price,
                  id:
                    ticketType[
                      type === "membership"
                        ? "membershipTypeId"
                        : "ticketTypeId"
                    ],
                }))
                ?.filter((s) => s.price != null)
            );

            if (!sectionTicketTypes?.length) {
              return undefined;
            }

            return {
              ...section,
              sectionTicketTypes,
              priceRange: getPriceRange(sectionTicketTypes, isAdmin),
            };
          })
          ?.filter((s) => !!s);

        const types = releasedZone[typeKey].map((ticketType) => ({
          ...ticketType,
          priceRange: ticketType.sections?.length
            ? getPriceRange(ticketType.sections, isAdmin)
            : null,
          sections: ticketType.sections?.map((section) =>
            addPricingToSection(filteredZone.sections, section, type)
          ),
        }));

        const priceRange = types.reduce(
          (acc, { priceRange }) => ({
            min: Math.min(priceRange?.min ?? 0, acc.min),
            max: Math.max(priceRange?.max ?? 0, acc.max),
          }),
          { min: 0, max: 0 }
        );

        return {
          ...releasedZone,
          ...filteredZone,
          sections,
          [typeKey]: types,
          priceRange,
        };
      }

      return {
        ...releasedZone,
        ...filteredZone,
        priceRange: getPriceRange(releasedZone[typeKey], isAdmin),
      };
    }) || []
  );
};

export const usePricing: PricingHook = ({
  filteredZones,
  releasedZones,
  type,
  activePromotions = [],
  isAdmin = false,
}) => {
  const [pricedZones, setPricedZones] = useState<PricedZone[]>(undefined);

  useEffect(() => {
    if (filteredZones && releasedZones) {
      setPricedZones(
        addPricingToZone(filteredZones, releasedZones, type, isAdmin)
      );
    }
  }, [filteredZones, releasedZones, isAdmin]);

  const getPrice = (seat: SeatsIOSeat): [number, number] => {
    let price = 0;
    let originalPrice = 0;

    const withPromo =
      activePromotions.find(
        ({ id, customId, type }) =>
          type === "getting" &&
          seat.id === id &&
          (!customId || customId === seat.customId)
      ) || false;

    if (seat?.ticketType) {
      const catLabel = seat?.category?.label;
      const { zone: zoneName, section: sectionName } = getCategoryDataFromLabel(
        catLabel
      );

      const releasedZone = releasedZones.find(
        ({ zone }) => zone.name === zoneName
      );

      const ticketType =
        type === "membership"
          ? releasedZone?.membershipTypes.find(
              ({ membershipTypeId }) =>
                membershipTypeId === seat.ticketType.value
            )
          : releasedZone?.ticketTypes?.find(
              ({ ticketTypeId }) => ticketTypeId === seat.ticketType.value
            );

      if (releasedZone.pricingLevel === ZonePricingLevel.Zone) {
        originalPrice = ticketType?.price;
      } else {
        // section level pricing
        const section = ticketType?.sections?.find(
          (s) => s.name === sectionName
        );
        originalPrice = section?.price;
      }
    }

    if (withPromo) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      price = withPromo.promotion?.price ?? 0;
    } else {
      price = originalPrice;
    }

    return [price, originalPrice];
  };

  return { pricedZones, getPrice };
};
