import { createContext, useReducer, ReactNode } from "react";
import has from "lodash/has";

import {
  CoverTypes,
  AlcoholFreeZoneTypes,
  SortTypes,
  SeatsIOSeat,
  SeatSelectTypes,
} from "~components/reservation/constants";
import {
  MembershipSeatingZoneType,
  MembershipType,
  MembershipZone,
  Zone,
  ZonePricingLevel,
} from "~graphql/sdk";
import * as Sentry from "@sentry/nextjs";

type MembershipSection = MembershipZone["zone"]["sections"][number];
export interface ReservationState {
  seatsLoading: boolean;
  seats: SeatsIOSeat[] | undefined;
  selectFailed: boolean;
  holdToken: string | undefined;
  zones: any[] | undefined;
  addons: any;
  allMembershipTypes: Partial<MembershipType>[];
  availableMembershipTypes: Partial<MembershipType>[];
  options: {
    amount: number | undefined;
    seatSelectType: SeatSelectTypes;
    coverType: CoverTypes;
    zoneType: AlcoholFreeZoneTypes;
    selectedSort: SortTypes;
    selectedZone: MembershipZone | undefined;
    selectedSection: MembershipSection | undefined;
    selectBestOnChange: boolean;
    validSeatSelection: boolean;
  };
}

export enum ReservationActionTypes {
  SET_SEAT_TYPE = "SET_SEAT_TYPE",
  SET_SEATS = "SET_SEATS",
  UNSET_SEATS = "UNSET_SEATS",
  SELECT_FAILED = "SELECT_FAILED",
  UPDATE_OPTIONS = "UPDATE_OPTIONS",
  RESET_OPTIONS = "RESET_OPTIONS",
  SET_TOKEN = "SET_TOKEN",
  SET_ZONES = "SET_ZONES",
  UPDATE_ADDON = "UPDATE_ADDON",
  SET_MEMBERSHIP_TYPES = "SET_MEMBERSHIP_TYPES",
  SEATS_CLICKED = "SEATS_CLICKED",
}

type ReservationActions =
  | {
      type:
        | ReservationActionTypes.UNSET_SEATS
        | ReservationActionTypes.RESET_OPTIONS
        | ReservationActionTypes.SEATS_CLICKED;
    }
  | { type: ReservationActionTypes.SET_SEATS; payload: SeatsIOSeat[] }
  | { type: ReservationActionTypes.SET_ZONES; payload: Zone[] }
  | {
      type: ReservationActionTypes.SET_SEAT_TYPE;
      payload: {
        id: string;
        ticketType: Partial<MembershipType>;
      };
    }
  | {
      type: ReservationActionTypes.SET_MEMBERSHIP_TYPES;
      payload: {
        membershipTypes: Partial<MembershipType>[];
      };
    }
  | { type: ReservationActionTypes.SET_TOKEN; payload: string }
  | {
      type: ReservationActionTypes.UPDATE_ADDON;
      payload: {
        id: string;
        quantity: number;
      };
    }
  | { type: ReservationActionTypes.SELECT_FAILED }
  | {
      type: ReservationActionTypes.UPDATE_OPTIONS;
      payload: Partial<Record<keyof ReservationState["options"], any>>;
    };

interface Props {
  children: ReactNode;
}

interface ReservationContextProps extends ReservationState {
  dispatch: (input: ReservationActions) => void;
}

const initialState: ReservationState = {
  seatsLoading: false,
  seats: undefined,
  holdToken: undefined,
  zones: undefined,
  addons: {},
  selectFailed: false,
  allMembershipTypes: [],
  availableMembershipTypes: [],
  options: {
    amount: undefined,
    seatSelectType: SeatSelectTypes.QUICK_SELECT,
    coverType: CoverTypes.ALL,
    zoneType: AlcoholFreeZoneTypes.NO_PREFERENCE,
    selectedSort: SortTypes.BEST_AVAILABLE,
    selectedZone: undefined,
    selectedSection: undefined,
    selectBestOnChange: true,
    validSeatSelection: false,
  },
};

function reducer(state: ReservationState, action: ReservationActions) {
  switch (action.type) {
    case ReservationActionTypes.SET_SEATS: {
      const defaultTicketType =
        state.availableMembershipTypes?.find(({ name }) => {
          return name.toLowerCase().includes("adult");
        }) ?? state.availableMembershipTypes[0];

      const seats = action.payload.map((seat) => {
        return {
          ...seat,
          seatsLoading: false,
          ticketType: seat.ticketType ?? {
            ...defaultTicketType,
            // for the select component :sad_face:
            value: defaultTicketType?.id,
          },
        };
      });

      return { ...state, seatsLoading: false, seats, selectFailed: false };
    }
    case ReservationActionTypes.UNSET_SEATS:
      return {
        ...state,
        seatsLoading: false,
        seats: undefined,
        selectFailed: false,
      };
    case ReservationActionTypes.SET_ZONES:
      return { ...state, zones: action.payload, selectFailed: false };
    case ReservationActionTypes.SET_SEAT_TYPE:
      if (!action.payload.ticketType) {
        Sentry.captureException(
          new Error(
            "ReservationActionTypes.SET_SEAT_TYPE: Attempting to set seat type without a ticket type."
          ),
          {
            tags: {
              action: action.type,
            },
            extra: {
              state: state.seats,
            },
          }
        );
      }

      return {
        ...state,
        seats: state.seats.map((s) => ({
          ...s,
          ...((s.id === action.payload.id ||
            s.customId === action.payload.id) && {
            ticketType: {
              ...action.payload.ticketType,
              value: action.payload.ticketType?.id,
            },
          }),
        })),
      };
    case ReservationActionTypes.SET_MEMBERSHIP_TYPES: {
      const allMembershipTypes = action.payload.membershipTypes;
      return {
        ...state,
        allMembershipTypes,
        availableMembershipTypes: allMembershipTypes,
      };
    }
    case ReservationActionTypes.SET_TOKEN:
      return { ...state, holdToken: action.payload };
    case ReservationActionTypes.UPDATE_OPTIONS: {
      const changedCoverType =
        has(action.payload, "coverType") &&
        state.options.coverType !== action.payload.coverType;
      const changedZoneType =
        has(action.payload, "zoneType") &&
        state.options.zoneType !== action.payload.zoneType;

      const selectedZone: ReservationState["options"]["selectedZone"] =
        action.payload.selectedZone ?? state.options.selectedZone;

      const selectedSection =
        action.payload.selectedSection ?? state.options.selectedSection;

      const availableTicketTypes =
        selectedZone?.pricingLevel === ZonePricingLevel.Zone
          ? selectedZone?.membershipTypes
          : selectedSection?.sectionTicketTypes;

      const availableMembershipTypes = getAvailableMembershipTypes({
        allTypes: state.allMembershipTypes,
        availableTypes: availableTicketTypes ?? [],
      });

      return {
        ...state,
        availableMembershipTypes,
        seats: changedCoverType || changedZoneType ? null : state.seats,
        options: {
          ...state.options,
          ...action.payload,
          ...(changedCoverType || changedZoneType
            ? {
                selectedZone: undefined,
                selectedSection: undefined,
              }
            : {}),
          ...(!has(action.payload, "selectBestOnChange")
            ? { selectBestOnChange: true }
            : {}),
        },
      };
    }
    case ReservationActionTypes.UPDATE_ADDON:
      return {
        ...state,
        addons: {
          ...state.addons,
          [action.payload.id]: action.payload.quantity,
        },
      };
    case ReservationActionTypes.SELECT_FAILED: {
      return {
        ...state,
        selectFailed: true,
      };
    }
    case ReservationActionTypes.RESET_OPTIONS: {
      return {
        ...initialState,
        availableMembershipTypes: state.allMembershipTypes,
      };
    }
    case ReservationActionTypes.SEATS_CLICKED: {
      return {
        ...state,
        seatsLoading: true,
      };
    }
    default:
      throw Error("Action type not defined");
  }
}

const ReservationContext = createContext<ReservationContextProps>(undefined);
ReservationContext.displayName = "ReservationContext";

const ReservationProvider = ({ children }: Props) => {
  const [state, dispatcher] = useReducer(reducer, initialState);

  const dispatch = (action) => {
    if (process.env.NODE_ENV !== "production") {
      // console.debug(`%c Action `, "background: #222; color: #FFC0CB", action);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    dispatcher(action);
  };

  if (process.env.NODE_ENV !== "production") {
    // console.debug(
    //   `%c State update `,
    //   "background: #222; color: #7dffd2",
    //   state
    // );
  }

  return (
    <ReservationContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export { ReservationProvider, ReservationContext };

function getAvailableMembershipTypes({
  allTypes = [],
  availableTypes,
}: {
  allTypes: Partial<MembershipType>[];
  availableTypes: (MembershipSeatingZoneType & { id })[];
}) {
  return allTypes.filter(({ id: membershipId }) => {
    return availableTypes?.find(
      ({ membershipTypeId, id: ticketTypeId, isActive }) => {
        return (
          (membershipId === membershipTypeId ||
            membershipId === ticketTypeId) &&
          (typeof isActive !== "undefined" ? isActive : true)
        );
      }
    );
  });
}
