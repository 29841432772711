import flatten from "lodash/flatten";
import { Zone, ZonePricingLevel, Ticket } from "~graphql/sdk";
import { getCategoryDataFromLabel } from "~lib/helpers";

interface PriceRange {
  min: number;
  max: number;
}

interface PricedZone extends Zone {
  priceRange: PriceRange;
}

export type PricingHookResponse = {
  pricedZones: PricedZone[];
  getPrice: (seat: any) => number | string;
};

const getPriceRange = (ticketTypes, isAdmin) => {
  ticketTypes = ticketTypes?.filter((tt) => tt.price != null);

  return {
    min:
      ticketTypes?.reduce(
        (min, i) =>
          isAdmin
            ? i.price < min
              ? i.price
              : min
            : i.price < min && i.price > 0
            ? i.price
            : min,
        ticketTypes[0]?.price
      ) ?? 0,
    max:
      ticketTypes?.reduce(
        (max, i) => (i.price > max ? i.price : max),
        ticketTypes[0]?.price
      ) ?? 0,
  };
};

const addPricingToSection = (sections, releasedSection, type) => {
  const zoneSection = sections?.find(
    ({ name }) => releasedSection.name === name
  );

  return zoneSection;
};

// filteredZones is just an array of zones, releaseZones is an array of releaseZones with nested linked zone
export const addPricingToZone = (
  filteredZones: any[],
  releasedZones: any[],
  type: string,
  isAdmin: boolean
): any[] => {
  const typeKey = type === "membership" ? "membershipTypes" : "ticketTypes";
  return (
    filteredZones?.map((filteredZone) => {
      const releasedZone = releasedZones.find(
        (releaseZone) => releaseZone.zone.name === filteredZone.name
      );

      const types = releasedZone[typeKey]
        .filter((ticketType) => ticketType.isActive)
        .map((ticketType) => ({
          ...ticketType,
          priceRange: ticketType.sections?.length
            ? getPriceRange(ticketType.sections, isAdmin)
            : null,
          sections: ticketType.sections?.map((section) =>
            addPricingToSection(filteredZone.sections, section, type)
          ),
        }));

      if (releasedZone.pricingLevel === ZonePricingLevel.Section) {
        const sections = filteredZone.sections?.map((section) => {
          const sectionTicketTypes = flatten(
            releasedZone[typeKey]
              ?.map((ticketType) => ({
                price: ticketType?.sections?.find(
                  ({ name }) => section.name === name
                )?.price,
                id:
                  ticketType[
                    type === "membership" ? "membershipTypeId" : "ticketTypeId"
                  ],
              }))
              ?.filter((s) => s.price != null)
          );

          return {
            ...section,
            sectionTicketTypes,
            ...(sectionTicketTypes?.length && {
              priceRange: getPriceRange(sectionTicketTypes, isAdmin),
            }),
          };
        });

        const priceRange = types.reduce(
          (acc, t) => ({
            min: Math.min(t.priceRange?.min ?? Infinity, acc.min),
            max: Math.max(t.priceRange?.max ?? -Infinity, acc.max),
          }),
          { min: Infinity, max: -Infinity }
        );

        return {
          ...releasedZone,
          ...filteredZone,
          sections,
          [typeKey]: types,
          priceRange,
        };
      } else {
        const types = releasedZone[typeKey]
          .filter((ticketType) => ticketType.isActive)
          .map((ticketType) => ({
            ...ticketType,
            priceRange: ticketType.sections?.length
              ? getPriceRange(ticketType.sections, isAdmin)
              : null,
            sections: ticketType.sections?.map((section) =>
              addPricingToSection(filteredZone.sections, section, type)
            ),
          }));

        // Add ticketTypes to sections if the pricing level is at the ZONE
        const sections = filteredZone.sections?.map((section) => {
          return {
            ...section,
            [typeKey]: types,
          };
        });

        return {
          ...releasedZone,
          ...filteredZone,
          sections,
          [typeKey]: types,
          priceRange: getPriceRange(types, isAdmin),
        };
      }
    }) || []
  );
};

export const getPrice = ({
  seat,
  releasedZones,
  activePromotions,
}): [number, number] => {
  let price = 0;
  let originalPrice = 0;

  const withPromo =
    activePromotions.find(
      ({ id, customId, type }) =>
        type === "getting" &&
        seat.id === id &&
        (!customId || customId === seat.customId)
    ) || false;

  if (seat?.ticketType) {
    const catLabel = seat?.category?.label;
    const { zone: zoneName, section: sectionName } = getCategoryDataFromLabel(
      catLabel
    );

    const releasedZone = releasedZones.find(
      ({ zone }) => zone.name === zoneName
    );

    const ticketType = releasedZone?.ticketTypes?.find(
      ({ ticketTypeId }) =>
        ticketTypeId === (seat.ticketType.id ?? seat.ticketType.value)
    );

    if (releasedZone.pricingLevel === ZonePricingLevel.Zone) {
      originalPrice = ticketType?.price;
    } else {
      // section level pricing
      const section = ticketType?.sections?.find((s) => s.name === sectionName);
      originalPrice = section?.price;
    }
  }

  if (withPromo) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    price = withPromo.promotion?.price ?? 0;
  } else {
    price = originalPrice;
  }

  return [price, originalPrice];
};
